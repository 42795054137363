body {
  background-color: rgb(233, 226, 226);
}

img {
  width: 30rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
